import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import ComplexTable from "views/admin/default/components/ComplexTable";
import tableDataComplex from "./variables/tableDataComplex.json";

import { FaTruck, FaWallet, FaRupeeSign } from "react-icons/fa";

import { LiaFileInvoiceSolid } from "react-icons/lia";

import { useEffect, useState } from "react";
import { getDashboardData } from "services/merchant.service";
import AllTicketTable from "./components/AllTicketTable";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [ticketStatus, setTicketStatus] = useState("ALL");

  useEffect(() => {
    getDashboardData().then((data) => {
      setDashboardData(data.data);
      console.log(data.data);
    });
  }, []);

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<LiaFileInvoiceSolid className="h-6 w-6" />}
          title={"Account Balance"}
          subtitle={`₹ ${0.0000.toFixed(2)||0}`}
        />
        <Widget
          icon={<LiaFileInvoiceSolid className="h-6 w-6" />}
          title={"Service Charge Collected"}
          subtitle={`₹ ${dashboardData?.dashboardData?.serviceChargePayoutAmount.toFixed(2)||0}`}
        />
          <Widget
          icon={<LiaFileInvoiceSolid className="h-6 w-6" />}
          title={"GST Charge Collected"}
          subtitle={`₹ ${dashboardData?.dashboardData?.gst.toFixed(2)||0}`}
        />
        <Widget
          icon={<LiaFileInvoiceSolid className="h-6 w-6" />}
          title={"Total Payout Amount"}
          subtitle2={`${dashboardData?.dashboardData?.totalNoDebits||0}`}
          subtitle={`₹ ${dashboardData?.dashboardData?.successPayoutAmount.toFixed(2)||0}`}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Payout Successful"}
          subtitle2={`${dashboardData?.dashboardData?.successNoDebits||0}`}
          subtitle={`₹${dashboardData?.dashboardData?.successPayoutAmount.toFixed(2)||0}`}
        />
        <Widget
          icon={<FaWallet className="h-6 w-6" />}
          title={"Payout Failed"}
          subtitle2={`${dashboardData?.dashboardData?.failedNoDebits||0}`}
          subtitle={`₹ ${dashboardData?.dashboardData?.failedPayoutAmount.toFixed(2)||0}`}
        />
        <Widget
          icon={<FaRupeeSign className="h-6 w-6" />}
          title={"Payout Pending"}
          subtitle2={`${dashboardData?.dashboardData?.pendingNoDebits||0}`}
          subtitle={`₹ ${dashboardData?.dashboardData?.pendingPayoutAmount.toFixed(2)||0}`}
        />
      </div>

      <div className="mt-10">
        <AllTicketTable
          ticketStatus={ticketStatus}
          tableDataComplex={dashboardData.latestDebits}
        />
      </div>
    </div>
  );
};

export default Dashboard;
